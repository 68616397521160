@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Dawning+of+a+New+Day&display=swap");
html,
body {
  height: 100%;
  min-height: 100%;
  font-family: "Cormorant Garamond", serif;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.banner {
  background-color: #e2c7aa;
  padding: 0.5em;
}

.banner-message {
  text-align: center;
  margin: 0;
  font-size: 1rem;
  color: white;
}

.nav-desktop {
  display: block;
}

.nav-bar-headers ul {
  list-style-type: none;
  overflow: hidden;
  text-align: center;
}

.nav-bar-headers li {
  display: inline;
  float: left;
}

.nav-bar-headers li a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.title {
  margin-left: 1em;
  font-size: 2rem;
  font-family: "Dawning of a New Day", cursive;
  font-weight: 500;
}

.logo {
  position: -webkit-sticky;
  position: sticky;
  margin: auto;
  left: 47%;
  width: 5%;
  top: 2;
}

.links {
  float: right;
}

.links a {
  margin-right: 5em;
  font-size: 1.2rem;
}

.shopping-bag {
  margin-right: 5em;
}

.nav-mobile {
  display: none;
  overflow: hidden;
  position: relative;
  font-family: "Dawning of a New Day", cursive;
}

.nav-bar-headers-mobile {
  margin-bottom: 0;
  list-style: none;
}

.hamburger {
  float: right;
  margin-right: 2vw;
}

.title-mobile {
  text-decoration: none;
  color: black;
}

.title-mobile-link {
  text-decoration: none;
  color: black;
}

.hamburger-menu {
  display: none;
  right: 0;
  background-color: white;
  z-index: 1;
}

.hamburger-menu ul {
  list-style: none;
}

.hamburger-menu li {
  margin-bottom: 0.5em;
  font-family: "Cormorant Garamond", serif;
}

.hamburger-menu li a {
  text-decoration: none;
  color: black;
}

.mobile-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.mobile-links li {
  width: 20%;
  margin: 0;
}

.mobile-nav-react-links {
  color: black;
  text-decoration: none;
  margin-bottom: 1vw;
}

.mobile-nav-react-links svg {
  display: block;
  margin: auto;
  padding: 1vw;
}

.hamburger-menu {
  display: none;
  border-bottom: 0.1em solid #e2c7aa;
  width: 100%;
  float: left;
  margin-bottom: 2em;
}

.home-page {
  height: 100%;
}

.welcome-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 30vw;
  margin-left: 3%;
  margin-right: 3%;
  text-align: center;
  padding: 10px;
  background-image: url("./Images/banner.webp");
  background-size: cover;
}

.welcome-banner button {
  font-family: "Times New Roman", Times, serif;
  padding: 0.75rem;
  background-color: white;
  border-color: #e2c7aa;
  border-style: solid;
  color: #e2c7aa;
  font-size: 0.9rem;
  cursor: pointer;
}

.welcome-banner button:active {
  background-color: #e2c7aa;
  color: white;
  border-color: white;
}

.home-page-about {
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-bottom: -80px;
  overflow: hidden;
  /*add*/
}

.home-page-about h1 {
  font-family: "Dawning of a New Day", cursive;
}

.home-blurb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 2vh;
  font-size: 0.9em;
  margin-bottom: 7vh;
}

.home-blurb img {
  width: 30%;
  height: 30%;
}

.home-blurb div {
  width: 30%;
}

.home-blurb-readmore {
  display: none;
}

.readmore-button {
  display: block;
  cursor: pointer;
}

.showless-button {
  display: none;
  cursor: pointer;
}

.shop-page {
  height: 100%;
}

.shop {
  display: block;
  text-align: center;
}

.shop h1 {
  margin-top: 3%;
}

.hide-shop {
  display: none;
}

.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.product-container {
  text-align: center;
  width: 30%;
  font-size: 0.7rem;
  min-height: 20vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.product-info-back-button svg {
  color: #e2c7aa;
}

.title-product {
  font-family: "Cormorant Garamond", serif;
  color: black;
  text-decoration: none;
}

.title-product-link {
  text-decoration: none;
}

.product-info-blurb {
  text-align: center;
}

.product-info-blurb h2 {
  color: black;
}

.product-card {
  width: 100%;
  min-height: 20vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.product-card img {
  border-radius: 1em;
}

.product-info-page {
  height: 100%;
}

.product {
  display: block;
  margin-top: 3%;
}

.hide-product {
  display: none;
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 2vw;
  min-height: 100%;
}

.flex-container-img {
  width: 25%;
  height: 50%;
  color: #e2c7aa;
}

.flex-container-img img {
  width: 100%;
}

.product-info {
  width: 25%;
  font-size: 1.3rem;
}

.product-info p {
  font-weight: 600;
}

.product-info-added-to-cart {
  color: #e2c7aa;
}

.product-info button {
  width: 35%;
  height: 5%;
  margin-top: 5%;
  font-family: "Times New Roman", Times, serif;
  background-color: white;
  border-color: #e2c7aa;
  border-style: solid;
  color: #e2c7aa;
  font-size: 0.6rem;
  cursor: pointer;
}

.product-info button:active {
  background-color: #e2c7aa;
  color: white;
  border-color: white;
}

.product-image-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 20%;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.product-image-active {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  -webkit-box-flex: 0;
      -ms-flex: 0 100%;
          flex: 0 100%;
  width: 100%;
}

.product-image-display img {
  width: 32%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-right: 1.33%;
  margin-top: 1rem;
}

.product-big-image-x {
  margin-top: 2em;
  color: white;
  margin-left: 2em;
}

.product-big-image-x:hover {
  color: #e2c7aa;
}

.quantity-selector {
  border: solid #e2c7aa 0.1em;
  width: 5vw;
  height: 3vh;
}

.mobile-product-page {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.product-image-display-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.product-image-active-mobile {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  width: 100%;
  margin-bottom: 0.2em;
}

.product-image-display-mobile img {
  width: 32.5%;
}

.product-info-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 1em;
}

.mobile-add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 90vw;
}

.mobile-add-to-cart select {
  margin-right: 5vw;
}

.mobile-product-descriptions {
  width: 80%;
}

.read-more-shipping {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.shipping-info {
  display: block;
  color: #e2c7aa;
  cursor: pointer;
}

.shipping-info {
  font-size: 2em;
}

.shipping-blurb {
  display: none;
  font-size: 1.5em;
}

.shipping-info-readless {
  display: none;
  cursor: pointer;
  color: #e2c7aa;
  font-size: 2em;
}

.cart-page {
  height: 100vh;
}

.cart {
  margin-left: 20%;
  margin-right: 20%;
}

.cart-headers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  text-align: center;
}

.blank-margin {
  width: 14% !important;
}

.cart-headers h1 {
  width: 20%;
  font-size: 1.5rem;
}

.cart-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 0.1em solid #e2c7aa;
  text-align: center;
  margin-left: 0.5em;
}

.cart-product h1 {
  width: 25%;
  font-size: 1.5rem;
}

.cart-product img {
  width: 14.5%;
}

.cart-product.cart-plus-div {
  min-width: 25%;
}

.cart-product img,
h1 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.cart-quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15vw;
}

.cart-plus-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #e2c7aa;
  width: 20%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 0.1em #e2c7aa solid;
  border-left: 0.1em #e2c7aa solid;
  border-bottom: 0.1em #e2c7aa solid;
  cursor: pointer;
}

.cart-plus {
  width: 100%;
  color: white;
  height: 35%;
}

.cart-spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 0.1em #e2c7aa solid;
  border-bottom: 0.1em #e2c7aa solid;
}

.cart-minus-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20%;
  background-color: #dddcdc;
  border-top: 0.1em #e2c7aa solid;
  border-right: 0.1em #e2c7aa solid;
  border-bottom: 0.1em #e2c7aa solid;
  cursor: pointer;
}

.cart-minus {
  width: 100%;
  height: 35%;
  color: black;
}

.cart-trash {
  width: 25%;
  height: 4rem;
  color: #e2c7aa;
  cursor: pointer;
}

form button {
  text-align: center;
  float: right;
  margin-right: 10vw;
  background-color: white;
  border: 0.1em solid #e2c7aa;
  color: #e2c7aa;
  font-size: 1rem;
}

form button:active {
  background-color: #e2c7aa;
  color: white;
  border-color: white;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  bottom: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 30vw;
  background-color: #e2c7aa;
  height: 6vh;
  text-align: center;
}

.footer a {
  color: black;
  margin-right: 1em;
  margin-left: 1em;
}

.footer svg {
  height: 3vh;
}

.preorder {
  text-align: center;
}

.preorder ul {
  list-style: none;
}

.success-page-div {
  width: 100%;
  height: 100vh;
}

.success-page-blurb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.success-page-blurb p {
  font-size: 1.3em;
  text-align: center;
}

.product-info-added-to-cart {
  margin-left: 1em;
}

.product-info-add-to-cart-button {
  background-color: white;
  border: solid #e2c7aa 0.2em;
  max-width: 35vw;
  max-height: 5.5vh;
}

@media (max-width: 768px) {
  .nav-desktop {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  .title {
    margin-left: 0;
    font-size: 0.9rem;
  }
  .links a {
    margin-right: 0.6em;
    font-size: 1rem;
  }
  .shopping-bag {
    margin-right: 0;
    width: 1rem;
  }
  .welcome-banner {
    max-height: 100vw;
  }
  .welcome-banner h1 {
    font-size: 1.5rem;
  }
  .welcome-banner h2 {
    font-size: 1rem;
  }
  .welcome-banner button {
    font-size: 0.5em;
  }
  .flex-container {
    display: none;
  }
  .product-container {
    width: 100%;
  }
  .card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-bottom: 150px;
  }
  .product-card {
    min-height: 30vh;
    min-width: 30vw;
  }
  .product-image-display {
    width: 40%;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .product-image-display img {
    width: 33.2%;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .mobile-product-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .shop h1 {
    margin-top: 0;
  }
  .product-info {
    width: 50%;
  }
  .product-info h1 {
    font-size: 1.5rem;
  }
  .product-info h3 {
    font-size: 1rem;
    margin-top: 0.2em;
    margin-bottom: 0.5em;
  }
  .shipping-blurb {
    font-size: 1rem;
  }
  .quantity {
    width: 35vw;
    height: 3.2vh;
  }
  .quantity h1 {
    font-size: 0.9rem;
  }
  .product-info button {
    width: 25vw;
    height: 3.2vh;
  }
  .product-info p {
    font-size: 1rem;
  }
  .cart {
    margin: 0;
  }
  .cart-headers h1 {
    font-size: 1rem;
  }
  .cart-product h1 {
    font-size: 1rem;
  }
  .home-page-about {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }
  .home-blurb {
    font-size: 0.6em;
    width: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin-bottom: 1vh;
  }
  .home-blurb img {
    width: 45%;
  }
  .home-blurb div {
    width: 45%;
  }
  .cart-quantity {
    width: 25%;
  }
  .cart-trash {
    height: 10vw;
  }
  .quantity-selector {
    border: solid #e2c7aa 0.1em;
    width: 12vw;
    height: 3vh;
    background-color: white;
  }
  .mobile-quantity-selector {
    border-color: #e2c7aa;
    background-color: white;
  }
}
